
import { defineComponent, ref, computed, PropType } from "vue";
import CustomIcon from "./CustomIcon.vue";
import PercentViewedBadge from "./PercentViewedBadge.vue";
import HistoryModule from "../store/modules/History/history";
import ContentModule from "../store/modules/Content/content";
import getIndicator from "../utility/getIndicator";
import convertDate from "../utility/convertDate";
import AvsRecallService from "../services/avsRecallService";
import { History } from "@/models/history/history";
export default defineComponent({
  components: { CustomIcon, PercentViewedBadge },
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
    recallableItems: {
      type: Array as PropType<History[]>,
      default: () => [],
    },
  },
  setup (props) {
    const selected = ref<History[]>([]);
    const loading = ref(false);
    const headers = [
      {
        text: "",
        value: "Icon",
      },
      {
        text: "title",
        value: "Title",
      },
      {
        text: "date",
        value: "Date",
      },
      {
        text: "viewed",
        value: "Viewed",
      },
    ];
    // index's to the array for each item so that vue can properly render the table in the event that
    // an item of content was printed/shared more than once
    const recallableItemsWithIndex = computed(() => props.recallableItems.map((item, index) => ({ ...item, Index: index + 1 })));
    const addOrRemoveFromSelected = (selection) => {
      // if item has already been recalled, it cannot be 're-selected'
      if (selection.IsRecalled) {
        return;
      }

      if (
        selected.value.some((item) => item.Index === selection.Index)
      ) {
        const index = selected.value.indexOf(selection);
        selected.value.splice(index, 1);
      } else {
        selected.value.push(selection);
      }
    };

    const recallEducation = () => {
      loading.value = true;
      // create list of objects of UniqueDocumentIds to send to the
      // backend for a recall
      const RecallItems = selected.value.map(item => {
        return { UniqueDocumentId: item.UniqueDocumentId };
      });

      AvsRecallService.recallEducation(RecallItems).then(() => {
        // there could be errors or success messages here, will be handled in later ticket
        loading.value = false;
        HistoryModule.updateRecalledItems(selected.value);
        ContentModule.updatePublishedHistory(selected.value);
        selected.value = [];
      });
    };

    return {
      loading,
      headers,
      getIndicator,
      convertDate,
      selected,
      addOrRemoveFromSelected,
      recallEducation,
      recallableItemsWithIndex,
    };
  },
});
